import {
  Box,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Tag,
  Text,
} from '@chakra-ui/react'
import throttle from 'lodash.throttle'
import { useEffect, useMemo, useRef, useState } from 'react'
import { IoSearch } from 'react-icons/io5'
import {
  Order_By,
  useCollectionReportsAutocompleteLazyQuery,
  useGetSpoofingReportsLazyQuery,
  useSpoofingReportsAutocompleteLazyQuery,
} from '../../generated/graphql'
import { useOrgID } from '../../hooks/id_token_claims'
import { genReportFilter, upsertFilter } from '../../hooks/report_table_filters'
import {
  useSearchKey,
  useSelectedAutocompleteReportId,
  useSelectedReportFilters,
  useSelectedReportType,
} from '../../pages/reports'
import { textSimilarity } from '../../utils'
import {
  KeyCode,
  ReportFilterType,
  ReportType,
  getStatusLabel,
} from '../../utils/constants'
import {
  METAVERSE_TYPES,
  DARK_WEB_TYPES,
  PLATFORM_TYPE_DISPLAY_MAP,
} from '../../utils/reports/report_utils'
import { getSpoofMatchUrl } from '../web2/spoof_match'
import { cleanUrl } from '../../utils/domain_utils'
import { DOPPEL_DARK_SECONDARY, DOPPEL_SECURE } from '../../utils/style'
import DoppelLink from '../report_detail/doppel_link'
import { maybeGetExternalIdWhereClauseFromSearch } from '@/hooks/queries'
import { ReportStatus } from '@/generated/enums'

const SEARCH_BAR_WIDTH = 300
const collectionReportsToAutocompleteList = (searchKey, collectionReports) => {
  const duplicationMap = {}
  return collectionReports
    .map((report) => {
      const originalSimilarity =
        textSimilarity(report.original_collection.collection_slug, searchKey) +
        textSimilarity(report.original_collection.collection_name, searchKey)
      const counterfeitSimilarity =
        textSimilarity(report.flagged_collection.collection_slug, searchKey) +
        textSimilarity(report.flagged_collection.collection_name, searchKey)
      const isCounterfeit = originalSimilarity < counterfeitSimilarity
      return {
        collection_slug:
          originalSimilarity >= counterfeitSimilarity
            ? report.original_collection.collection_slug
            : report.flagged_collection.collection_slug,
        collection_name:
          originalSimilarity >= counterfeitSimilarity
            ? report.original_collection.collection_name
            : report.flagged_collection.collection_name,
        is_counterfeit: isCounterfeit,
      }
    })
    .filter((item) => {
      if (!duplicationMap[item.collection_slug]) {
        duplicationMap[item.collection_slug] = true
        return true
      }
      return false
    })
}

const ReportsSearchBar = ({ isOrgUnifiedView, autocompleteClickCallback }) => {
  const dropdownRef = useRef(null)
  const orgId = useOrgID()
  const [showAutocomplete, setShowAutocomplete] = useState(false)

  const [localSearchKey, setLocalSearchKey] = useState('')
  const [searchKey, setSearchKey] = useSearchKey()
  const [, setSelectedAutocompleteReportId] = useSelectedAutocompleteReportId()
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const [searchCollectionReports, { data: collectionReportsData }] =
    useCollectionReportsAutocompleteLazyQuery({
      notifyOnNetworkStatusChange: true,
    })
  const [searchSpoofingReports, { data: spoofingReportsData }] =
    useSpoofingReportsAutocompleteLazyQuery({
      notifyOnNetworkStatusChange: true,
    })
  const whereClauseFromExternalId = maybeGetExternalIdWhereClauseFromSearch(
    localSearchKey.trim(),
    isOrgUnifiedView ? null : orgId,
  )
  const noResultsClause = { id: { _eq: '00000000-0000-0000-0000-000000000000' } }
  const [getExternalIdReport, { data: externalIdData, loading: externalIdLoading }] =
    useGetSpoofingReportsLazyQuery({
      variables: {
        spoofingReportsWhere: whereClauseFromExternalId ?? noResultsClause,
        orderBy: [{ created_at: Order_By.Asc }],
      },
    })

  const externalIdReport = externalIdData?.spoofing_reports[0] || null

  const handleShowAutocomplete = (searchKey) => {
    if (!searchKey) {
      return null
    }
    setShowAutocomplete(true)
    getExternalIdReport()
    if (selectedReportType === ReportType.NFTS) {
      searchCollectionReports({
        variables: {
          searchKey,
          limit: 5,
          collectionReportsWhere: {
            _and: [{ organization_id: { _eq: orgId } }],
          },
        },
      })
    } else {
      const whereClauses: any = [
        {
          organization_id: { _eq: orgId },
          report_status: { _neq: ReportStatus.INTERNAL_ARCHIVED },
        },
      ]
      if (selectedReportType == ReportType.METAVERSE) {
        whereClauses.push({ type: { _in: METAVERSE_TYPES } })
      } else if (selectedReportType == ReportType.DARK_WEB) {
        whereClauses.push({ type: { _in: DARK_WEB_TYPES } })
      } else {
        whereClauses.push({
          platform: { product: { _eq: PLATFORM_TYPE_DISPLAY_MAP[selectedReportType] } },
        })
      }
      searchSpoofingReports({
        variables: {
          searchKey: cleanUrl(searchKey),
          limit: 5,
          spoofingReportsWhere: {
            _and: whereClauses,
          },
        },
      })
    }
  }

  const throttledSearchKey = useMemo(
    () => throttle(handleShowAutocomplete, 400),
    [orgId, selectedReportType],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowAutocomplete(false)
    }
  }

  // sync local search key with search key
  useEffect(() => {
    setLocalSearchKey(searchKey)
  }, [searchKey])

  if (selectedReportType === ReportType.DARK_WEB) {
    // search not implemented for dark web yet
    return null
  }

  // reportId filters to a specific report
  // should still set key when setting reportId, for display
  const triggerSearchKey = (key: string, reportId: string = null) => {
    setSearchKey(key.toLowerCase())
    setSelectedAutocompleteReportId(reportId)
    setSelectedReportFilters(
      upsertFilter(
        genReportFilter(ReportFilterType.Search, key.toLowerCase()),
        selectedReportFilters,
      ),
    )
  }

  const triggerSearch = () => {
    // hotfix: open modal instead of searching if valid external id is entered
    if (externalIdLoading) {
      return
    }
    if (externalIdReport) {
      autocompleteClickCallback(externalIdReport.id)
      return
    }

    triggerSearchKey(localSearchKey)
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === KeyCode.ArrowLeft || event.keyCode === KeyCode.ArrowRight) {
      event.stopPropagation()
    }
    if (event.keyCode === KeyCode.Enter) {
      triggerSearch()
    }
    if (event.keyCode === KeyCode.Escape) {
      setShowAutocomplete(false)
    }
  }

  const autocompleteReportsList = [
    ...(spoofingReportsData?.search_spoofing_reports || []),
  ]
  if (externalIdReport) autocompleteReportsList.unshift(externalIdReport)

  return (
    <Flex width={SEARCH_BAR_WIDTH}>
      <InputGroup size="md">
        <Input
          fontSize={13}
          onChange={(e) => {
            setLocalSearchKey(e.target.value)
            throttledSearchKey(e.target.value)
          }}
          onKeyDown={handleKeyDown}
          placeholder="Search"
          pr="4.5rem"
          value={localSearchKey}
        />

        <InputRightElement right="5px">
          <IconButton
            aria-label="Search"
            backgroundColor={DOPPEL_DARK_SECONDARY}
            fontSize={12}
            h="1.75rem"
            icon={<IoSearch />}
            onClick={() => {
              triggerSearch()
            }}
          ></IconButton>
        </InputRightElement>
      </InputGroup>

      <Flex
        borderRadius="8px"
        direction="column"
        overflow="hidden"
        position="absolute"
        top="110px"
      >
        {showAutocomplete &&
          selectedReportType == ReportType.NFTS &&
          collectionReportsToAutocompleteList(
            searchKey,
            collectionReportsData?.search_collection_reports || [],
          ).map((item, index) => {
            return (
              <Flex
                _hover={{
                  background: DOPPEL_SECURE,
                }}
                backgroundColor={DOPPEL_DARK_SECONDARY}
                cursor="pointer"
                key={index}
                onClick={() => {
                  triggerSearchKey(item.collection_slug)
                  setShowAutocomplete(false)
                }}
                padding="12px"
                ref={dropdownRef}
                width="500px"
                zIndex="2"
              >
                <Flex direction="column">
                  <Text fontSize={13}>{item.collection_name}</Text>

                  <Text fontSize={12}>{item.collection_slug}</Text>
                </Flex>

                <Flex flex={1} justify="flex-end">
                  {item.is_counterfeit ? (
                    <Tag colorScheme="red" fontSize={11} size="sm">
                      Flagged
                    </Tag>
                  ) : (
                    <Tag colorScheme="green" fontSize={11} size="sm">
                      Original
                    </Tag>
                  )}
                </Flex>
              </Flex>
            )
          })}

        {showAutocomplete &&
          selectedReportType != ReportType.NFTS &&
          autocompleteReportsList.map((report, index) => {
            return (
              <Box
                _hover={{
                  background: DOPPEL_SECURE,
                }}
                backgroundColor={DOPPEL_DARK_SECONDARY}
                cursor="pointer"
                key={index}
                onClick={() => {
                  autocompleteClickCallback(report.id)
                  setShowAutocomplete(false)
                }}
                padding="12px"
                ref={dropdownRef}
                width={SEARCH_BAR_WIDTH + 200}
                zIndex="2"
              >
                <Flex>
                  <Text fontSize={13} fontWeight="semibold" noOfLines={1}>
                    {report.flagged_url}
                  </Text>

                  <Spacer />

                  <Tag fontSize={9} marginLeft={2} paddingX={2} size="xs">
                    {getStatusLabel(report.report_status, selectedReportType)}
                  </Tag>
                </Flex>

                {report.spoof_matches.map(
                  (match) =>
                    getSpoofMatchUrl(match) != report.flagged_url && (
                      <DoppelLink
                        fontSize={11}
                        name={getSpoofMatchUrl(match)}
                        noOfLines={1}
                      />
                    ),
                )}
              </Box>
            )
          })}
      </Flex>
    </Flex>
  )
}

export default ReportsSearchBar
